import AcceptCookiesManager from "@web/vpt-assets/assets/js/utilities/cookies/acceptCookiesManager";
import LoginDataLayerGenerator from "./dataLayer/loginDataLayerGenerator";
import DataLayerManager from "./dataLayer/dataLayerManager";
import {onCLS, onINP, onLCP, onFID, onFCP, onTTFB} from 'web-vitals/attribution';

export default class TrackersManager {
    constructor() {
        let googleTagManagerScript = document.querySelector('[data-entity="google-tag-manager-script"]');
        if (!googleTagManagerScript || typeof googleTagManagerScript === 'undefined' || !myApp.config.parameters.googleTagManagerKey) {
            return;
        }
        this.dataLayerManager = new DataLayerManager();
        let today = new Date().getTime();

        this.alwaysEnabled = myApp.config.parameters.isOffice || (today >= new Date("2024-09-14 00:00:00").getTime() && today <= new Date("2024-09-15 23:59:00").getTime());
        this.acceptCookiesManager = new AcceptCookiesManager('cookies-accept', this.alwaysEnabled);

        this.updateCookieConsent();
        this.initializeDataLayers();
        this.eventDelegation();
        if (myApp.config.parameters.isOffice) {
            onCLS(this._sendToGoogleAnalytics.bind(this));
            onINP(this._sendToGoogleAnalytics.bind(this));
            onLCP(this._sendToGoogleAnalytics.bind(this));
            onFID(this._sendToGoogleAnalytics.bind(this));
            onFCP(this._sendToGoogleAnalytics.bind(this));
            onTTFB(this._sendToGoogleAnalytics.bind(this));

        }
    }

    eventDelegation() {
        document.querySelector('[data-entity="google-tag-manager-script"]').addEventListener(AcceptCookiesManager.onLoadEvent, () => {
            this.updateCookieConsent();
            this.dataLayerManager.pushData(JSON.stringify({
                'event': 'cookie_consent_click',
                'event_detail_1': this.acceptCookiesManager.cookiesStatus
            }));
        });
    }

    updateCookieConsent() {
        if (!this.acceptCookiesManager.cookiesStatus) {
            return;
        }

        function gtag() {
            if (typeof dataLayer != 'undefined') {
                dataLayer.push(arguments);
            }
        }

        gtag('consent', 'update', {
            ad_user_data: this.acceptCookiesManager.cookiesStatus,
            ad_personalization: this.acceptCookiesManager.cookiesStatus,
            ad_storage: this.acceptCookiesManager.cookiesStatus,
            analytics_storage: this.acceptCookiesManager.cookiesStatus
        });
        this.dataLayerManager.pushData(JSON.stringify({
            'event': 'cookie_consent_update',
            'event_detail_1': this.acceptCookiesManager.cookiesStatus
        }));

        if (this.acceptCookiesManager.cookiesStatus === AcceptCookiesManager.granted) {
            this.dataLayerManager.pushData(JSON.stringify({
                'event': 'cookie_consent_preference'
            }));
            this.dataLayerManager.pushData(JSON.stringify({
                'event': 'cookie_consent_marketing'
            }));
            this.dataLayerManager.pushData(JSON.stringify({
                'event': 'cookie_consent_analytics'
            }));
        }
    }

    initializeDataLayers() {
        new LoginDataLayerGenerator();

        if (myApp.purchaseDataLayer) {
            this.dataLayerManager.pushData(myApp.purchaseDataLayer);
            this.dataLayerManager.push(DataLayerManager.prototype.type_client);
        }
        if (myApp.enhancedConversionDataLayer) {
            this.dataLayerManager.pushData(myApp.enhancedConversionDataLayer);
        }
        if (myApp.refundDataLayer) {
            this.dataLayerManager.pushData(myApp.refundDataLayer);
        }
        if (myApp.checkoutDataLayer) {
            this.dataLayerManager.pushData(myApp.checkoutDataLayer);
        }
        if (typeof dataLayers !== 'undefined') {
            dataLayers.forEach((dataLayer) => {
                this.dataLayerManager.pushData(JSON.stringify(dataLayer));
            });
        }
        $('[data-events]').on('click', (event) => {
            let currentTarget = $(event.currentTarget);
            let events = currentTarget.data('events');
            this.generate(events);
        });
    }

    generate(events) {
        Object.keys(events).forEach((k) => events[k] == null && delete events[k]);
        this.dataLayerManager.pushData(JSON.stringify(events));
    }

    _sendToGoogleAnalytics({name, delta, value, id, entries, rating, attribution}) {
        let debugTarget = attribution ? attribution.largestShiftTarget || attribution.element || attribution.eventTarget || '' : '(not set)';
        switch (name) {
            case 'CLS':
                debugTarget = attribution.largestShiftTarget;
                break;
            case 'INP':
                debugTarget = attribution.interactionTarget;
                break;
            case 'LCP':
                debugTarget = attribution.element;
                break;
        }

        let data = {
            event: 'coreWebVitals',
            webVitalsMeasurement: {
                metric_id: id,
                metric_name: name,
                metric_value: value,
                metric_delta: delta,
                metric_rating: rating,
                debug_target: debugTarget,
                debug_event_type: attribution ? attribution.eventType || '' : '',
                debug_timing: attribution ? attribution.loadState || '' : '',
                event_time: attribution ? attribution.largestShiftTime || (attribution.lcpEntry && attribution.lcpEntry.startTime) || attribution.eventTime || '' : ''
            }
        };

        this.dataLayerManager.pushData(JSON.stringify(data));
    }
}
