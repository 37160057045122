import AjaxManager from "./ajaxManager";
import DataLayerManager from "./trackers/dataLayer/dataLayerManager";
import UserLoginHandler from "@web/vpt-assets/assets/js/modules/userLoginRegister/userLoginHandler";
import RememberPasswordHandler from "@web/vpt-assets/assets/js/modules/userLoginRegister/rememberPasswordHandler";
import UserRegisterHandler from "@web/vpt-assets/assets/js/modules/userLoginRegister/userRegisterHandler";
import regeneratorRuntime from "regenerator-runtime";
import CaptchaManager from "./captcha/captchaManager";
import InfoActiveAccountHandler from "@web/vpt-assets/assets/js/modules/userLoginRegister/infoActiveAccountHandler";
import GoogleLoginHandler from "@web/vpt-assets/assets/js/modules/userLoginRegister/googleLoginHandler";
import ChangePasswordHandler from "@web/vpt-assets/assets/js/modules/userLoginRegister/changePasswordHandler";
import NewsletterDataLayerGenerator from "./trackers/dataLayer/newsletterDataLayerGenerator";

export default class UserManager {

    constructor() {
        window.onGoogleLibraryLoad = () => {
            new GoogleLoginHandler();
        }
        this.captchaManager = new CaptchaManager();
        this.dataLayerManager = new DataLayerManager();
        this.newsletterDatalayerGenerator = new NewsletterDataLayerGenerator();
        this.ajaxManager = new AjaxManager();
        new UserLoginHandler({
            loginCallback: this.login.bind(this),
            successLogin: this.successLogin.bind(this)
        });
        new InfoActiveAccountHandler({
            isUserLoggedIn: myApp.config.isUserLoggedIn,
            isActivateAccount: activateAccountInfo['userActivate']
        });
        new UserRegisterHandler({
            registerCallback: this.register.bind(this),
            saveRegisterCookie: this.saveRegisterCookie.bind(this)
        });
        new RememberPasswordHandler({
            rememberPassword : this.rememberPassword.bind(this)
        });
        new ChangePasswordHandler({
            changePasswordCallback : this.changePassword.bind(this)
        });

        this.setUserTypeTrackingText();
        this.eventDelegation();
    };

    eventDelegation() {
        $('#userModal').on('custom-modal-show', () => {
            this.captchaManager.captchaInitialize($('#form-user-login'))
        });
        $('#userChangePasswordModal').on('custom-modal-show', () => {
            this.captchaManager.captchaInitialize($('#form-change-password'))
        });
    }

    async login() {
        const formLogin = document.querySelector('#form-user-login');
        await this.captchaManager.executeCaptcha(formLogin);
        const userWebLoginUrl = myApp.config.routing.userWebLogin;
        const dataUser = new FormData(formLogin);

        return await fetch(userWebLoginUrl, {
            method: 'POST',
            body: dataUser
        }).then(r => r.json());
    };

    setUserTypeTrackingText() {
        const cookieUserTypeTrackingText = myApp.browserStorageService.getCookie('userTypeTrackingText');
        myApp.config.parameters.userTypeTrackingText = decodeURIComponent(cookieUserTypeTrackingText).replace(/[+]/g, ' ');
    };

    successLogin() {
        this.dataLayerManager.push(DataLayerManager.prototype.type_client);
        if (document.querySelector('#form-user-login').dataset.redirect) {
            window.location.href = document.querySelector('#form-user-login').dataset.redirect;
        } else {
            location.reload();
        }
    }

    async register() {
        const formRegister = document.querySelector('#form-user-register');
        await this.captchaManager.executeCaptcha(formRegister);
        const registerUserUrl = myApp.config.routing.registerUser;
        const dataUser = new FormData(formRegister);
        if (dataUser.get('newsletter')) {
            this.newsletterDatalayerGenerator.generateDataLayerData(
                NewsletterDataLayerGenerator.register,
                NewsletterDataLayerGenerator.eventStatusSuccess
            );
        }
        return await fetch(registerUserUrl, {
            method: 'POST',
            body: dataUser
        }).then(r => r.json());
    }

    async rememberPassword() {
        const formRememberPassword = document.querySelector('#form-user-remember-password');
        await this.captchaManager.executeCaptcha(formRememberPassword);
        const rememberPasswordUserUrl = myApp.config.routing.rememberPassword;
        const dataUser = new FormData(formRememberPassword);

        return await fetch(rememberPasswordUserUrl, {
            method: 'POST',
            body: dataUser
        }).then(r => r.json());
    }

    async changePassword() {
        const formChangePassword = document.querySelector('#form-change-password');
        await this.captchaManager.executeCaptcha(formChangePassword);
        let params = new URLSearchParams(location.search);
        const changePasswordUserUrl = myApp.config.routing.changePassword;
        const changePasswordData = new FormData(formChangePassword);
        changePasswordData.append('userId', params.get('userId'));
        changePasswordData.append('token', params.get('token') );
        changePasswordData.append('rememberPasswordId', params.get('rememberPasswordId'));



        return await fetch(changePasswordUserUrl, {
            method: 'POST',
            body: changePasswordData
        }).then(r => r.json());
    }

    async saveRegisterCookie() {
        const data = {
            email: $('#userEmail').val()
        };

        const url = myApp.config.routing.saveUserCookie;

        const callback = () => {
            this.dataLayerManager.push(DataLayerManager.prototype.type_client);
        };

        this.ajaxManager.ajaxCall('POST', url, data, callback);
    }
};
