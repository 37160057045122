import Form from "../entity/form";
import DataLayerManager from "./trackers/dataLayer/dataLayerManager";
import NewsletterDataLayerGenerator from "./trackers/dataLayer/newsletterDataLayerGenerator";

export default class SubscribeManager {
    constructor() {
        this.newsletterDatalayerGenerator = new NewsletterDataLayerGenerator();
        this.newsletterBetweenChollosLocation = "banner entre chollos";
        this.newsletterSidebarLocation = "barra lateral";
        this.dataLayerManager = new DataLayerManager();
        this.eventDelegation();
    }

    eventDelegation() {
        if (myApp.deviceDetector.isXsScreen()) {
            $("body").on("click", '[data-banner][data-type="2"] img', () => {
                $(event.currentTarget).parents("[data-banner='banner-newsletter']").find('[data-field="input-email"]').focus();
            });
            $("body").on("focus", '[data-field="input-email"]', () => {
                $(".affix-container-bottom-xs").hide();
            });
            $("body").on("blur", '[data-field="input-email"]', () => {
                $(".affix-container-bottom-xs").show();
            });
        }
    }

    subscribe(formId) {
        var form = new Form(formId);
        form.setCallback((data) => {
            var interval;
            interval = window.setInterval(() => {
                let subscriptionModal = $('#subscription_success')
                if (subscriptionModal.hasClass('in')) {
                    let headText = data.emailExists ? 'newsletterbox.subscribed.head' : 'newsletterbox.thankyou.head'
                    let bodyText = data.emailExists ? 'newsletterbox.subscribed.body' : 'newsletterbox.thankyou.body'
                    subscriptionModal.find('.custom-header-title').html(myApp.translator[headText]);
                    subscriptionModal.find('.custom-modal-body').html(myApp.translator[bodyText]);
                    window.clearInterval(interval);
                }
            }, 1000);
            this.dataLayerManager.push(DataLayerManager.prototype.type_client);
            if (!data.emailExists) {
                this.newsletterDatalayerGenerator.generateDataLayerData(formId, NewsletterDataLayerGenerator.eventStatusSuccess);
            }

        });
        myApp.formSubmitterManager.submitAjax(form);
        var origin = $("#" + formId).find("input[name=id_origen]").val();
        var location = this.newsletterBetweenChollosLocation;
        if (origin == 1) {
            location = this.newsletterSidebarLocation;
        }
    }
}
