import InputValidator from "@web/vpt-assets/assets/js/utilities/validations/inputValidator";
import NewsletterDataLayerGenerator from "../../trackers/dataLayer/newsletterDataLayerGenerator";
import ScrollManager from "@web/vpt-assets/assets/js/utilities/scrollManager";

export default class NewsletterSubscriptionValidator {
    constructor() {
        this.newsletterDatalayerGenerator = new NewsletterDataLayerGenerator();
        this.inputValidator = new InputValidator();
        this.scrollManager = new ScrollManager();
        this.arrayFormsId = [
            '#lightbox',
            '#newsletter-widget',
            '#form_newsletter',
            '#newsletter-banner',
            '#newsletter-footer'
        ];
        this.eventDelegation();
    }

    eventDelegation() {
        let forms = [];
        this.arrayFormsId.forEach(idForm => {
            let form = document.querySelector(idForm);
            if (form){
                forms.push(form);
                form.addEventListener("submit", event => {
                    this.newsletterDatalayerGenerator.generateDataLayerData(
                        event.currentTarget.id,
                        NewsletterDataLayerGenerator.eventStatusTry
                    );
                    event.preventDefault();
                    const inputMail = form.querySelector(' [name="email"]');
                    let error = true;

                    if (!this.inputValidator.validateEmail(null, inputMail, null)) {
                        inputMail.classList.add("border-error");
                        error = false;
                    }

                    if (error) {
                        let $form = $(event.target);
                        let modalPath = $form.data('wait-modal');
                        myApp.customModalManager.openModal('#' + $form.data('modal-id'), modalPath, undefined, undefined, $('#save'));
                        myApp.subscribeManager.subscribe($form.attr('id'));
                    }
                });
            }
        });

        window.addEventListener("scroll", () => {
            forms.forEach(element => {
                if (this.scrollManager.isElementPartiallyVisible(element) && !parseInt(element.dataset.seen)) {
                    element.dataset.seen = 1;
                    this.newsletterDatalayerGenerator.generateDataLayerData(
                        element.getAttribute('id'),
                        NewsletterDataLayerGenerator.eventStatusStart
                    );
                }

            });
        });

        document.querySelectorAll('[data-open="registerAnchor"]').forEach((e) => {
            e.addEventListener('click', () => {
                this.newsletterDatalayerGenerator.generateDataLayerData(
                    NewsletterDataLayerGenerator.register,
                    NewsletterDataLayerGenerator.eventStatusStart
                );
            });
        });
    }
}