import DataLayerManager from "./dataLayerManager";
import ScrollManager from "@web/vpt-assets/assets/js/utilities/scrollManager";

export default class NewsletterDataLayerGenerator {
    constructor() {
        this.dataLayerManager = new DataLayerManager();
        this.scrollManager = new ScrollManager();
    }

    generateDataLayerData(eventCategory, eventDetail1) {
        let parameters = {
            event: "newsletter",
            event_category: eventCategory,
            event_detail_1: eventDetail1,
        };
        let getParams = new URLSearchParams(window.location.search);

        if (getParams.has('utm_source') &&
            getParams.has('utm_medium') &&
            getParams.has('utm_campaign')) {
            parameters.source = getParams.get('utm_source');
            parameters.medium = getParams.get('utm_medium');
            parameters.campaign = getParams.get('utm_campaign');
            if (getParams.has('utm_content')) {
                parameters.content = getParams.get('utm_content');
            }
            if (getParams.has('utm_term')) {
                parameters.term = getParams.get('utm_term');
            }
        } else {
            let referrer = document.referrer;
            if (referrer.includes(window.location.host)) {
                referrer = window.location.host;
            }
            if (referrer == '' || !referrer) {
                parameters.source = 'direct';
                parameters.medium = window.location.host;
            } else {
                if (referrer.includes('google')) {
                    parameters.source = 'google';
                    parameters.medium = 'organic';
                } else {
                    parameters.source = referrer;
                    parameters.medium = 'referral';
                }
            }
        }
        if (true || myApp.config.parameters.isOffice) {
            console.log(parameters);
        }
        this.dataLayerManager.pushData(JSON.stringify(parameters));
    }
}

NewsletterDataLayerGenerator.eventStatusStart = "newsletter_start";
NewsletterDataLayerGenerator.eventStatusTry = "newsletter_try";
NewsletterDataLayerGenerator.eventStatusSuccess = "newsletter_success";

NewsletterDataLayerGenerator.lightbox = 'lightbox';
NewsletterDataLayerGenerator.register = 'user-registration';
NewsletterDataLayerGenerator.book = 'check-purchase ';